import React from 'react'
import insta from "../assests/insta.svg";
import facebook from "../assests/facebook.svg";
import twitter from "../assests/twitter.svg";
import linkedin from "../assests/linkedin.svg";
import "../styles/footer.scss"

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="social-media">
        <img src={twitter} alt="" className="twitter" />
        <img src={facebook} alt="" className="facebook" />
        <img src={insta} alt="" className="insta" />
        <img src={linkedin} alt="" className="linkedin" />
      </div>
      <div className="footer-nav">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/#/about">About Us</a>
          </li>
          <li>
            <a href="/#/approach">Our Approach</a>
          </li>
          <li>
            <a href="/#/blog">Blogs</a>
          </li>
          <li>
            <a href="/#/joinus" className="joinusnavitem">
              Join Us
            </a>
          </li>
        </ul>
      </div>
      <h1>Join Us in Making a Difference</h1>
      <div className="joinusbtn">
        <a > Join Us</a>
      </div>
      <p>Subscribe to our newsletter for our latest stories.</p>
      <div className='subscribeFooterBtn'>
        <hr />
        <a>Subscribe</a>
        <hr />
      </div>
    </div>
  );
}

export default Footer