import React from 'react'
import Home from '../component/Home'
import Layout from '../component/Layout'
import { useEffect } from 'react'
const Homepage = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
      <Layout>
        <Home />
      </Layout>
    </>
  );
}

export default Homepage