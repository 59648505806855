import React from 'react'
import Layout from './../component/Layout';
import About from '../component/About';
import { useEffect } from 'react';
const Aboutpage = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
   <Layout>
    <About/>
   </Layout>
  )
}

export default Aboutpage