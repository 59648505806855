import React from 'react'
import "../styles/joinus.scss"
import insta from "../assests/insta.svg";
import facebook from "../assests/facebook.svg";
import twitter from "../assests/twitter.svg";
import linkedin from "../assests/linkedin.svg";
import chooseus from "../assests/chooseusimg.png"
import volunteerimg from "../assests/teamimg-joinus.png"
import partnerwithus from "../assests/partnerwithus.png"
import whyus_resp from "../assests/whyus_resp.png"
const Joinus = () => {
  return (
    <div className="joinus-container">
      <div className="joinus-bg">
        <div className="joinus-content">
          <div>
            {/* <img src={silaigramlogo} /> */}
            <h1>Join Us</h1>
          </div>
          <p>Building a better today for tomorrow</p>
          <a className="joinusbtn">Join Us</a>
        </div>
        <div className="socialmedia">
          <hr />
          <div className="socialmediaicon">
            <img src={twitter} alt="" className="twitter" />
            <img src={facebook} alt="" className="facebook" />
            <img src={insta} alt="" className="insta" />
            <img src={linkedin} alt="" className="linkedin" />
          </div>
          <hr className="secondhr" />
        </div>
      </div>
      <div className="whychooseus-section">
        <div className="whychooseus-content">
          <div className="image-section">
            <img src={chooseus} className='whyus_desktop' />
            {/* <img src={whyus_resp} className='whyus_resp' /> */}
          </div>
          <div className="text-section">
            <h1>Why choose us?</h1>
            <ul>
              <li>
                Maximized CSR Impact: Direct, tangible results in community
                upliftment.
              </li>
              <li>Broadened Reach: Access diverse, grassroots networks.</li>
              <li>
                Enhanced Brand Value: Strengthen corporate social
                responsibility.
              </li>
              <li>
                Strategic Partnership: Leverage our expertise for impactful
                solutions.
              </li>
              <li>
                Unparalleled Livelihood Creation Goal: Join our mission to
                develop 1 million sustainable livelihoods.
              </li>
            </ul>
            <div className="joinusbtnposition">
              <a className="joinusbtn">Join Us</a>
            </div>
          </div>
        </div>
      </div>
      <div className="volunteer-section">
        <div className="volunteer-content">
          <div className="volunteer-img">
            <img src={volunteerimg} />
          </div>
          <div className="volunteer-text">
            <h1>Ready to Volunteer?</h1>
            <p>
              Got enthusiasm? Check. Ready to make a difference? Double check.{" "}
            </p>
            <p>
              Simply pop your details into our quick form, and boom – you're on
              the journey to something amazing.
            </p>
            <p>We'll buzz you soon to get the adventure rolling! </p>
            <div className="volunteernowbtn">
              <a> Volunteer Now!</a>
            </div>
          </div>
        </div>
      </div>
      <div className="partner-section">
        <div className="partner-content">
          <div className="partner-img">
            <img src={partnerwithus} />
          </div>
          <div className="partner-text">
            <div className="titleDescription">
              <h1>Partner with us!</h1>
              <p>
                Join Silaigram’s mission to shape a better future! Fill out our
                form, team up with us, and let’s drive lasting change together.
                We’re uniting with corporations and NGOs to build sustainable
                livelihoods for a million people. Your expertise, our
                innovation—partners in creating a resilient legacy. Let's make a
                difference that lasts. Partner with us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Joinus