
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from './pages/Homepage';
import Aboutpage from './pages/Aboutpage';
import ApproachPage from './pages/ApproachPage';
import Blogpage from './pages/Blogpage';
import Joinuspage from './pages/Joinuspage';
import Joinusformpage from './component/Joinusforms';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<Aboutpage />} />
        <Route path="/approach" element={<ApproachPage />} />
        <Route path="/blog" element={<Blogpage />} />
        <Route path="/joinus" element={<Joinuspage />} />
        <Route path="/joinusform" element={<Joinusformpage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
