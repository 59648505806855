import React from 'react'
import "../styles/homepage.scss"
import silaigramlogo from "../assests/silaigramlogo.png"
import insta from "../assests/insta.svg"
import facebook from "../assests/facebook.svg"
import twitter from "../assests/twitter.svg"
import linkedin from "../assests/linkedin.svg"
import whoWeAre from "../assests/whoweare.png"
import cardImg1 from "../assests/cardImg1.png"
import cardImg2 from "../assests/cardImg2.png"
import cardImg3 from "../assests/cardImg3.png"
import partnerImg from "../assests/partnerImg.png"
import clippath1 from "../assests/Clip path group (1).png"
import clippath2 from "../assests/Clip path group.png";
import bs from "../assests/business-standard-logo.png"
import ashoka from "../assests/ashoka.png"
import ikea from "../assests/ikea logo.png"
import { Link } from 'react-router-dom';
import OppSlider from '../slider/OpportunitySlider/OppSlider'

const Home = () => {
  return (
    <div className="homepage-container">
      <div className="about-bg">
        <div className="about-content">
          <div className="silaigramhomeLogo">
            <img src={silaigramlogo} />
          </div>
          <p>Want to become a volunteer?</p>
          <div className="btnposition">
            <a className="">Join Us</a>
          </div>
        </div>
        <div className="socialmedia">
          <hr />
          <div className="socialmediaicon">
            <img src={twitter} alt="" className="twitter" />
            <img src={facebook} alt="" className="facebook" />
            <img src={insta} alt="" className="insta" />
            <img src={linkedin} alt="" className="linkedin" />
          </div>
          <hr className="secondhr" />
        </div>
      </div>
      <div className="who-we-are-section">
        <div className="who-we-are-content">
          <div className="who-we-are-img">
            <img src={whoWeAre} />
          </div>
          <div className="who-we-are-text">
            <div className="titleDescription">
              <h1>Who we are?</h1>
              <p>
                Silaigram is dedicated to reshaping how livelihoods are created
                and sustained. Our mission is to create sustainable, impactful
                changes by providing 1 million livelihoods through dynamic
                partnerships with non-governmental organizations (NGOs) and
                corporate bodies. As well as addressing a spectrum of issues
                like poverty alleviation, quality education, comprehensive
                healthcare, and equal opportunity for all. Our projects and
                initiatives are multi-dimensional and designed for maximum
                impact. We focus on being an impact multiplier, recognizing that
                uniting efforts and resources can bring about a more
                significant, systemic change than acting alone.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="opportunities-section">
        <div className="opportunities-content">
          <h1>Your Impact & Opportunities</h1>
        </div>
        <div className="opportunities-cards">
          <div className="cardssss">
            <OppSlider />
          </div>
          <div className="cards">
            <div className="cardd">
              <img src={cardImg3} />
              <div className="title">
                <h1>For Social Organiztions</h1>
                <ul>
                  <li>
                    We collaborate with NGOs to identify, support, and scale
                    grassroots initiatives aimed at enhancing livelihoods,
                    especially in marginalized communities.
                  </li>
                  <li>
                    Our support extends to capacity building, resource
                    mobilization, and sharing best practices.
                  </li>
                </ul>
              </div>
            </div>
            <div className="cardd">
              <img src={cardImg2} />
              <div className="title">
                <h1>For Corporates</h1>
                <ul>
                  <li>
                  By partnering with forward-thinking corporate, we leverage their expertise, resources, and networks to drive change. 
                  </li>
                  <li>
                  These partnerships are not only CSR (Corporate Social Responsibility)
                  initiatives but are integrated into the core business strategies for a
                  more profound, sustainable impact.
                  </li>
                </ul>
              </div>
            </div>
            <div className="cardd">
              <img src={cardImg1} />
              <div className="title">
                <h1>For Volunteers</h1>
                <ul>
                  <li>
                  Make a tangible difference in the community, contributing to sustainable change and personal growth.
                  </li>
                  <li>
                  Develop critical professional skills and gain valuable experience that can advance your career.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partner-section">
        <div className="partner-container">
          <div className="partner-content">
            <div className="partner-text">
              <h1>Partner with us!</h1>
              <p>
                Silaigram invites corporations and non-governmental
                organizations (NGOs) to join us in our ambitious mission to
                create 1 million livelihoods. We believe that through strategic
                collaboration, we can amplify our impact and achieve lasting
                change. Your expertise, resources, and vision, combined with our
                innovative approach to social development, can forge a
                partnership that not only addresses immediate needs but also
                builds sustainable and resilient communities.
              </p>
              <p>
                Let's work together to create a difference that echoes through
                generations.
              </p>
              <div className="joinusbtnn">
                <a>Join Us</a>
              </div>
            </div>
            <div className="partner-img">
              <img src={partnerImg} />
            </div>
          </div>
        </div>
      </div>
      <div className="media">
        <div className="media-content">
          <div className="img-1">
            <img src={clippath2} />
          </div>
          <div className="media-imgtitle">
            <h1>In the media!</h1>
            <div className="media-img">
              <img src={ashoka} />
              <img src={ikea} className="ikeaImg" />
              <img src={bs} />
            </div>
          </div>
          <div className="img-2">
            <img src={clippath1} />
          </div>
        </div>
      </div>
      <div className="mediares">
        <h1>In the media!</h1>
        <div className="media-imgres">
          <img src={ashoka} />
          <img src={ikea} />
          <img src={bs} />
        </div>
      </div>
    </div>
  );
}

export default Home