import React from 'react'
import Layout from './../component/Layout';
import Approach from '../component/Approach';
import { useEffect } from 'react';
const ApproachPage = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <Layout>
        <Approach/>
    </Layout>
  )
}

export default ApproachPage