import React from "react";
import "../slider/partnerslider.scss"
import "swiper/css";
import "swiper/css/effect-coverflow";
import { EffectCoverflow } from "swiper/modules";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import partnerwhitesvg from "../assests/partner-whitesvg.svg";
import partnerlogo from "../assests/partners-logo.png"
import partnergraysvg from "../assests/partner-graysvg.svg";

import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([EffectCoverflow, Navigation]);

const Partnerslider = () => {
  
  return (
    <>
      <div className="slider-section-of-partner">
        <div className="slider-swiper-main-container-partner">
          <Swiper
            effect="coverflow"
            grabCursor={false}
            centeredSlides={true}
            slidesPerView={"auto"}
            spaceBetween={0}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: true,
            }}
            initialSlide={1}
            className="swiper_containers"
            navigation={{
              nextEl: ".swiper-button-next", // CSS class for the next button
              prevEl: ".swiper-button-prev", // CSS class for the previous button
            }}
            // loop= {true}
            //  loop= {true}
            // loopAdditionalSlides= {2}
          >
            <SwiperSlide>
              <div className="slider-content-container">
                <div className="slide-content-bg-partner">
                  <img src={partnerwhitesvg} />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Duis aliquam gravida
                    tellus lobortis sit laoreet. Leo{" "}
                  </p>
                </div>
                <img src={partnerlogo} className="partnerlogo" />
                <div className="namedate">
                  <p className="name">Lorem ipsum</p>
                  <p>01/11/2023</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-content-container">
                <div className="slide-content-bg-partner">
                  <img src={partnergraysvg} />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Duis aliquam gravida
                    tellus lobortis sit laoreet. Leo turpis aliquam amet odio
                    sit eu neque id. Proin feugiat aliquam facilisis enim
                    suspendisse elit sed diam tellus.
                  </p>
                </div>
                <img src={partnerlogo} className="partnerlogo" />
                <div className="namedate">
                  <p className="name">Lorem ipsum</p>
                  <p>01/11/2023</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-content-container">
                <div className="slide-content-bg-partner">
                  <img src={partnerwhitesvg} />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Duis aliquam gravida
                    tellus lobortis sit laoreet. Leo{" "}
                  </p>
                </div>
                <img src={partnerlogo} className="partnerlogo" />
                <div className="namedate">
                  <p className="name">Lorem ipsum</p>
                  <p>01/11/2023</p>
                </div>
              </div>
            </SwiperSlide>

            {/* <div className="swiper-button-prev"></div> */}
            {/* <div className="swiper-button-next"></div> */}
          </Swiper>
        </div>
      </div>
      <div className="slider-section-of-partnerResponsive">
        <div className="slider-swiper-main-container-partner">
          <Swiper
            effect="coverflow"
            grabCursor={false}
            centeredSlides={true}
            slidesPerView={"auto"}
            spaceBetween={90}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: true,
            }}
            initialSlide={1}
            className="swiper_containers"
            navigation={{
              nextEl: ".swiper-button-next", // CSS class for the next button
              prevEl: ".swiper-button-prev", // CSS class for the previous button
            }}
            // loop= {true}
            //  loop= {true}
            // loopAdditionalSlides= {2}
          >
            <SwiperSlide>
              <div className="slider-content-container">
                <div className="slide-content-bg-partner">
                  <img src={partnerwhitesvg} />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Duis aliquam gravida
                    tellus lobortis sit laoreet. Leo{" "}
                  </p>
                </div>
                <img src={partnerlogo} className="partnerlogo" />
                <div className="namedate">
                  <p className="name">Lorem ipsum</p>
                  <p>01/11/2023</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-content-container">
                <div className="slide-content-bg-partner">
                  <img src={partnergraysvg} />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Duis aliquam gravida
                    tellus lobortis sit laoreet. Leo turpis aliquam amet odio
                    sit eu neque id. Proin feugiat aliquam facilisis enim
                    suspendisse elit sed diam tellus.
                  </p>
                </div>
                <img src={partnerlogo} className="partnerlogo" />
                <div className="namedate">
                  <p className="name">Lorem ipsum</p>
                  <p>01/11/2023</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="slider-content-container">
                <div className="slide-content-bg-partner">
                  <img src={partnerwhitesvg} />
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Duis aliquam gravida
                    tellus lobortis sit laoreet. Leo{" "}
                  </p>
                </div>
                <img src={partnerlogo} className="partnerlogo" />
                <div className="namedate">
                  <p className="name">Lorem ipsum</p>
                  <p>01/11/2023</p>
                </div>
              </div>
            </SwiperSlide>

            {/* <div className="swiper-button-prev"></div> */}
            {/* <div className="swiper-button-next"></div> */}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Partnerslider;
