import React from 'react'
import Layout from './../component/Layout';
import Blog from '../component/Blog';
import { useEffect } from 'react';

const Blogpage = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
   <Layout>
    <Blog/>
   </Layout>
  )
}

export default Blogpage