import React from 'react'
import Layout from './../component/Layout';
import Joinus from '../component/Joinus';
import { useEffect } from 'react';
const Joinuspage = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
   <Layout>
    <Joinus/>
   </Layout>
  )
}

export default Joinuspage