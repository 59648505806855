import React from "react";
import insta from "../assests/insta.svg";
import facebook from "../assests/facebook.svg";
import twitter from "../assests/twitter.svg";
import linkedin from "../assests/linkedin.svg";
import "../styles/blog.scss";
import wasu from "../assests/wasu-img-blog-page.png"
import ashoka from "../assests/ashoka.svg"
import team1 from "../assests/team-img-blog-page.png"
import team2 from "../assests/team2-img-blog-page.png"
import bs from "../assests/bs-blog-page.png"
import mphasis from "../assests/mphasis-blog-page.png"
import ikea from "../assests/ikea-blog-page.png"
import afs from "../assests/afs.svg"
import bssvg from "../assests/bs.svg"
import mphasissvg from "../assests/mphasis.svg"
import ikeasvg from "../assests/ikea.svg"
import Partnerslider from "../slider/Partnerslider";

import "swiper/css";
import "swiper/css/effect-coverflow";
import { EffectCoverflow } from "swiper/modules";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import FeaturedBlog from "../slider/FeaturedSlider/FeaturedBlog";
SwiperCore.use([EffectCoverflow, Navigation]);
const Blog = () => {
  return (
    <div className="blog-container">
      <div className="blog-bg">
        <div className="blog-content">
          <div>
            {/* <img src={silaigramlogo} /> */}
            <h1>Read about Us</h1>
          </div>
          <p>Subscribe to our newsletter for our latest stories.</p>
          <div className="btnposition">
            <a className="">Join Us</a>
          </div>
        </div>
        <div className="socialmedia">
          <hr />
          <div className="socialmediaicon">
            <img src={twitter} className="twitter" />
            <img src={facebook} className="facebook" />
            <img src={insta} className="insta" />
            <img src={linkedin} className="linkedin" />
          </div>
          <hr className="secondhr" />
        </div>
      </div>
      <div className="feature-section">
        <div className="feature-content">
          <h1>We have proudly featured in</h1>
          <div className="feature-cards">
            <div className="cardd">
              <div>
                <img src={wasu} />
              </div>
              <div className="card-content">
                <h2>Wasudev: Upcycling clothing for rural development</h2>
                <p>
                  Tackling both unemployment and waste, Silaigram transforms
                  upcycled cloth into sustainable products while employing young
                  women throughout rural India.
                </p>
                <div className="card-footerrr">
                  <div className="ashokaimg">
                    <img src={ashoka} />
                  </div>
                  <div className="ashokaname">
                    <h6>Ashoka Young Changemaker</h6>
                    <p>August 4, 2020</p>
                  </div>
                </div>
                <div className="readmore">
                  <a>Read More</a>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div>
                <img src={team1} />
              </div>
              <div className="card-content">
                <h2>Silaigram | An Initiative to Empower Women</h2>
                <p>
                  The Sustainable Development Goals (SDGs) are a universal call
                  of action to end poverty, protect the planet and ensure that
                  all people enjoy peace and prosperity. For the SDGs to be
                  achieved in all countries,
                </p>
                <div className="card-footerrr">
                  <div className="ashokaimg">
                    <img src={afs} />
                  </div>
                  <div className="ashokaname">
                    <h6>Ashoka Young Changemaker</h6>
                    <p>August 4, 2020</p>
                  </div>
                </div>
                <div className="readmore">
                  <a>Read More</a>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div>
                <img src={team2} />
              </div>
              <div className="card-content">
                <h2>
                  {" "}
                  Learned To Step Out Of Comfort Zone And Take My Responsibility
                  As A Global Citizen
                </h2>
                <p>
                  “After my Exchange Experience, AFS has been an integral part
                  of my life. The YES Program has been a turning point in my
                  life.
                </p>
                <div className="card-footerrr">
                  <div className="ashokaimg">
                    <img src={afs} />
                  </div>
                  <div className="ashokaname">
                    <h6>Ashoka Young Changemaker</h6>
                    <p>August 4, 2020</p>
                  </div>
                </div>
                <div className="readmore">
                  <a>Read More</a>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div>
                <img src={bs} />
              </div>
              <div className="card-content">
                <h2>
                  Ashoka Innovators for Public Shortlists' Teens for 1st Global
                  Edition of the Young Changemakers Programme
                </h2>
                <p>
                  Ashoka Innovators for the Public, which supports the world's
                  largest network of social entrepreneurs, has shortlisted 12
                  teenagers from India for the first Global edition of their
                  Young Changemakers Programme.
                </p>
                <div className="card-footerrr">
                  <div className="ashokaimg">
                    <img src={bssvg} />
                  </div>
                  <div className="ashokaname">
                    <h6>Ani</h6>
                    <p>Jul 12 2019</p>
                  </div>
                </div>
                <div className="readmore">
                  <a>Read More</a>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div>
                <img src={mphasis} />
              </div>
              <div className="card-content">
                <h2>Mpasis</h2>
                <p>
                  Silaigram, founded by Wasudev Mishra, is a social impact
                  startup aiming to provide sustainable livelihoods in rural
                  parts of India.
                </p>
                <div className="card-footerrr">
                  <div className="ashokaimg">
                    <img src={mphasissvg} />
                  </div>
                  <div className="ashokaname">
                    <h6>Lorem ipsum </h6>
                    <p>May 13 2020</p>
                  </div>
                </div>
                <div className="readmore">
                  <a>Read More</a>
                </div>
              </div>
            </div>
            <div className="cardd">
              <div>
                <img src={ikea} />
              </div>
              <div className="card-content">
                <h2>Ikea Fouundation</h2>
                <p>
                  The IKEA Foundation is excited to be supporting the Ashoka
                  India Livelihoods For All programme to enable young
                  changemakers like Wasudev to improve the lives of people in
                  their communities.
                </p>
                <div className="card-footerrr">
                  <div className="ashokaimg">
                    <img src={ikeasvg} />
                  </div>
                  <div className="ashokaname">
                    <h6>Ikea Foundation</h6>
                    <p>March 10 2020</p>
                  </div>
                </div>
                <div className="readmore">
                  <a>Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="featuredcardHideSection">
        <FeaturedBlog />
      </div>

      <div className="partner-container">
        <h1>What our partners say!</h1>
        <Partnerslider />
      </div>
    </div>
  );
};

export default Blog;
