import React from "react"
import "../styles/joinusforms.scss"
import silaigramlogo from "../assests/silaigramlogo.png"

const Joinusform = () => {
    return(
        
        <div className="page-container">
            <div className="forms-background">
                <div className="page-content">
                    <p>You are just few steps away from becoming the next</p>
                    <h1>CHANGEMAKER</h1>
                </div>
                <div className="forms-container"> 
                    <form className="form" action="">
                        <div className="forms-section">
                            <label for="name">Name</label>
                            <input type="text" id="name" name="name" placeholder="Full Name" required></input>
                        </div>
                        <div className="forms-section">
                            <label for="address">Address</label>
                            <input type="text" id="address" name="address" placeholder="Address" required></input>
                        </div>
                        <div className="forms-section">   
                            <label for="contact">Contact No</label>
                            <input type="text" id="contact" name="contact" placeholder="Contact No." required ></input>
                        </div>
                        <div className="forms-section">
                            <label for="email">Email</label>
                            <input type="text" id="email" placeholder="Email" name="email" required></input>
                        </div> 
                        <div className="forms-section2">
                            <label for="discover">How did you discover Silaigram?</label><br/>
                            <input type="text" id="discover" name="discover"  placeholder="How did you discover Silaigram?"required></input>
                            
                            <label for="inspiration">What inspires you to join us?</label><br/>
                            <input type="text" id="inspiration" name="inspiration"  placeholder="What inspires you to join us?"required></input>
                        
                            <label for="role">In what role do you see yourself contributing?</label><br/>
                            <input type="text" id="role" name="role"  placeholder="In what role do you see yourself contributing?"required></input>
                        </div>
                            {/* <div className="button"> */}
                                <button className="button" type="submit" >Submit</button>
                        {/* </div>     */}
                    </form>               
                </div>      
            </div>    
        </div>    
    )
}    

export default Joinusform