// import React, { useState } from "react";
// import { Link, NavLink } from "react-router-dom";

// import logo from "../assests/silaigramsvglogo.svg";
// import "../styles/header.scss"
// import { FiMenu } from "react-icons/fi";
// import { AiOutlineClose } from "react-icons/ai";
// import { HiOutlineMenu } from "react-icons/hi";
// const Header = () => {
//   const [activeItem, setActiveItem] = useState("");
//   const handleItemClick = (itemName) => {
//     setActiveItem(itemName);
//   };
//   return (
//     <div className="navbarContainer">
//       <div className=" navbar-inner ">
//         <nav className="navbar navbar-expand-lg navbar-light ">
         
//           <div className="" id="navbarSupportedContent">
//             <ul className="">
//               <li className="nav-item">
//                 <NavLink
//                   className={activeItem === "home" ? "active" : ""}
//                   to="/"
//                 >
//                   Home
//                 </NavLink>
//               </li>
//               <li class="nav-item  dropdown">
//                 <NavLink
//                   className={activeItem === "about" ? "active" : ""}
//                   onClick={() => handleItemClick("about")}
//                   to="/about"
//                 >
//                   About us
//                 </NavLink>
//               </li>

//               <li className="nav-item">
//                 <NavLink
//                   className={activeItem === "solutions" ? "active" : ""}
//                   onClick={() => handleItemClick("solutions")}
//                   to="/approach"
//                 >
//                   Our Approach
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className={activeItem === "solutions" ? "active" : ""}
//                   onClick={() => handleItemClick("solutions")}
//                   to="/blog"
//                 >
//                   Blog
//                 </NavLink>
//               </li>
//               <li className="nav-item">
//                 <NavLink
//                   className={activeItem === "blogs" ? "active" : ""}
//                   onClick={() => handleItemClick("blogs")}
//                   to="/joinus"
//                 >
//                   Join Us
//                 </NavLink>
//               </li>
//             </ul>
//           </div>
//         </nav>
//         <NavLink className="navbar-logo" to="/">
//           <img src={logo} alt="" className="logo" />
//           {/* <p>infivent</p> */}
//         </NavLink>

//         <div className="responsive-nav">
//           <a
//             href="#"
//             data-bs-toggle="offcanvas"
//             data-bs-target="#offcanvasWithBothOptions"
//             aria-controls="offcanvasWithBothOptions"
//           >
//             <HiOutlineMenu />
//           </a>

//           <div
//             class="offcanvas offcanvas-end"
//             data-bs-scroll="true"
//             tabindex="-1"
//             id="offcanvasWithBothOptions"
//             aria-labelledby="offcanvasWithBothOptionsLabel"
//           >
//             <div class="offcanvas-header">
//               <div class="offcanvas-title" id="offcanvasWithBothOptionsLabel">
//                 <NavLink className="navbar-logo" to="/">
//                   <img src={logo} alt="" style={{ width: "140px" }} />
//                 </NavLink>
//               </div>
//               <a
//                 data-bs-dismiss="offcanvas"
//                 aria-label="Close"
//                 className="close-btn"
//               >
//                 <AiOutlineClose />
//               </a>
//             </div>
//             <div class="offcanvas-body">
//               <ul className="navbar-nav mr-auto">
//                 <li className="nav-item">
//                   <NavLink
//                     className={activeItem === "home" ? "active" : ""}
//                     to="/"
//                   >
//                     Home
//                   </NavLink>
//                 </li>
//                 <li class="nav-item  dropdown">
//                   <NavLink
//                     className={activeItem === "about" ? "active" : ""}
//                     onClick={() => handleItemClick("about")}
//                     to="/about"
//                   >
//                     About us
//                   </NavLink>
//                 </li>

//                 <li className="nav-item">
//                   <NavLink
//                     className={activeItem === "solutions" ? "active" : ""}
//                     onClick={() => handleItemClick("solutions")}
//                     to="/approach"
//                   >
//                     Our Approach
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink
//                     className={activeItem === "solutions" ? "active" : ""}
//                     onClick={() => handleItemClick("solutions")}
//                     to="/blog"
//                   >
//                     Blog
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink
//                     className={activeItem === "blogs" ? "active" : ""}
//                     onClick={() => handleItemClick("blogs")}
//                     to="/joinus"
//                   >
//                     Join Us
//                   </NavLink>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assests/silaigramsvglogo.svg";

// import logo from "../images/Infi4nityLogo.svg";
import "../styles/header.scss"
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import Approach from './Approach';

const Header = () => {
  const [activeItem, setActiveItem] = useState("");
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };
  return (
    <>
      <div className="navbarContainer">
        <div className=" navbar-inner ">
          <NavLink className="navbar-logo" to="/">
            <img src={logo} alt="" className="logo" />
            {/* <p>infivent</p> */}
          </NavLink>
          <nav className="navbar navbar-expand-lg navbar-light ">
            {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink
                    className={activeItem === "home" ? "active" : ""}
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li class="nav-item  dropdown">
                  <NavLink
                    className={activeItem === "about" ? "active" : ""}
                    onClick={() => handleItemClick("about")}
                    to="/about"
                  >
                    About us
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    className={activeItem === "solutions" ? "active" : ""}
                    onClick={() => handleItemClick("solutions")}
                    to="/approach"
                  >
                    Our Approach
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={activeItem === "blogs" ? "active" : ""}
                    onClick={() => handleItemClick("blogs")}
                    to="/blog"
                  >
                    Blogs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={activeItem === "blogs" ? "active" : ""}
                    onClick={() => handleItemClick("blogs")}
                    to="/joinus"
                  >
                    Join Us
                  </NavLink>
                </li>
              </ul>
              {/* <form className="form-inline my-2 my-lg-0">
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button
            className="btn btn-outline-success my-2 my-sm-0"
            type="submit"
          >
            Search
          </button>
        </form> */}
            </div>
          </nav>
          <div className="responsive-nav">
            <a
              href="#"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBothOptions"
              aria-controls="offcanvasWithBothOptions"
            >
              <GiHamburgerMenu />
            </a>

            <div
              class="offcanvas offcanvas-end"
              data-bs-scroll="true"
              tabindex="-1"
              id="offcanvasWithBothOptions"
              aria-labelledby="offcanvasWithBothOptionsLabel"
            >
              <div class="offcanvas-header">
                <div class="offcanvas-title" id="offcanvasWithBothOptionsLabel">
                  <NavLink className="navbar-logo" to="/">
                    <img src={logo} alt="" style={{ width: "140px" }} />
                  </NavLink>
                </div>
                <a
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  className="close-btn"
                >
                  <AiOutlineClose />
                </a>
              </div>
              <div class="offcanvas-body">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <NavLink
                      className={activeItem === "home" ? "active" : ""}
                      onClick={() => handleItemClick("home")}
                      to="/"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li class="nav-item  dropdown">
                    <NavLink
                      className={activeItem === "about" ? "active" : ""}
                      onClick={() => handleItemClick("about")}
                      to="/about"
                    >
                      About us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={activeItem === "solutions" ? "active" : ""}
                      onClick={() => handleItemClick("solutions")}
                      to="/approach"
                    >
                      Our Approach
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={activeItem === "blogs" ? "active" : ""}
                      onClick={() => handleItemClick("blogs")}
                      to="/blog"
                    >
                      Blogs
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={activeItem === "blogs" ? "active" : ""}
                      onClick={() => handleItemClick("blogs")}
                      to="/joinus"
                    >
                      Join Us
                    </NavLink>
                  </li>{" "}
               
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

