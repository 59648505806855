import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.scss';
import './oppslider.scss';
import cardImg1 from "../../assests/cardImg1.png"
import cardImg2 from "../../assests/cardImg2.png"
import cardImg3 from "../../assests/cardImg3.png"

const opportunitiesData = [
  {
    img: cardImg1,
    title: "For Social Organizations",
    description: [
      "We collaborate with NGOs to identify, support, and scale grassroots initiatives aimed at enhancing livelihoods, especially in marginalized communities.",
      "Our support extends to capacity building, resource mobilization, and sharing best practices.",
    ],
  },
  {
    img: cardImg2,
    title: "For Corporates",
    description: [
      "By partnering with forward-thinking corporate, we leverage their expertise, resources, and networks to drive change. ",

      "These partnerships are not only CSR (Corporate Social Responsibility )      initiatives but are integrated into the core business strategies for a more profound, sustainable impact.",
    ],
  },
  {
    img: cardImg3,
    title: "For Volunteers",
    description: [
      "Make a tangible difference in the community, contributing to sustainable change and personal growth.",

      "Develop critical professional skills and gain valuable experience that can advance your career.",
    ],
  },
];

const OppSlider = () => {
  return (
    <div className='hidesection'>
      <Swiper
      spaceBetween={200}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 2000 }}
      breakpoints={{
        576: {
          slidesPerView: 1,
        },
      }}
    >
      {opportunitiesData.map((item, index) => (
        <SwiperSlide key={index}>
          <div className="slide">
            <img src={item.img} alt={item.title} />
            <div className="title">
              <h1>{item.title}</h1>
              <ul>
                {item.description.map((desc, i) => (
                  <li key={i}>{desc}</li>
                ))}
              </ul>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    </div>
  );
};

export default OppSlider;
