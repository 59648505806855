import React from "react";
import insta from "../assests/insta.svg";
import facebook from "../assests/facebook.svg";
import twitter from "../assests/twitter.svg";
import linkedin from "../assests/linkedin.svg";
import "../styles/approach.scss";
import model from "../assests/approach-model.png"
import model1 from "../assests/model1.png"
import model2 from "../assests/model2.png";

import model3 from "../assests/model3.png";

import model4 from "../assests/model4.png";
import model5 from "../assests/model5.png";

import modeldivider from "../assests/approach-divider.png"

import modeldivider1 from "../assests/Group 116.png";

const Approach = () => {
  return (
    <div className="approach-container">
      <div className="approach-bg">
        <div className="approach-content">
          <div>
            {/* <img src={silaigramlogo} /> */}
            <h1>From Loom to Legacy</h1>
          </div>
          <p>Want to become a volunteer?</p>
          <div className="btnposition">
            <a className="" href="/#/Joinusform">Join Us</a>
          </div>
        </div>
        <div className="socialmedia">
          <hr />
          <div className="socialmediaicon">
            <img src={twitter} className="twitter" />
            <img src={facebook} className="facebook" />
            <img src={insta} className="insta" />
            <img src={linkedin} className="linkedin" />
          </div>
          <hr className="secondhr" />
        </div>
      </div>
      <div className="model-section">
        <div className="model-content">
          <div className="model-text">
            <h1>Our Model</h1>
            <div className="modellist">
              <p>
                Our working model at Silaigram is a strategic collaboration
                among three key entities: non-governmental organizations (NGOs),
                corporates, and Silaigram. Central to this model is our role as
                a facilitator and integrator of resources and efforts aimed at
                creating sustainable livelihoods.
              </p>
              <ul>
                <li>
                  Corporates: They contribute through their social corporate
                  responsibility initiatives, providing essential resources such
                  as funding, expertise, and materials. 
                </li>
                <li>
                  NGOs: They are deeply embedded within local communities, and
                  are instrumental in the on-the-ground implementation of these
                  resources. Their intimate understanding of local contexts and
                  needs allows for the tailored creation of employment
                  opportunities in rural areas, directly building livelihoods
                  and enhancing community welfare.
                </li>
                <li>
                  Silaigram: We play a crucial role in efficiently channeling
                  these resources to the most impactful areas, ensuring
                  transparency and alignment with our overarching goals.
                </li>
              </ul>
              <p>
                This triad forms a robust mechanism not just for resource
                allocation but for amplifying impact through synergy and
                collaboration. Our joint efforts extend beyond job creation,
                encompassing broader social objectives like poverty reduction,
                education equality, accessible healthcare, and promoting equal
                opportunities for all. This holistic approach is designed to
                foster systemic change, establishing a sustainable ecosystem of
                development that empowers and transforms communities.
              </p>
            </div>
          </div>
          <div className="model-img">
            <img src={model} />
          </div>
        </div>
      </div>
      <div className="modelOutcomes-section">
        <div className="modelOutcomes-content">
          <h1>Our Model Outcomes</h1>
          <div className="modelOutcomes">
            <div className="modelColumn1">
              <div className="">
                <img src={model1} />
              </div>

              <div className="column1text">
                <h3>Sustainable Community Development</h3>
                <p>
                  Our approach ensures that development is not only immediate
                  and direct but also sustainable, empowering communities to be
                  resilient and self-sufficient in the long term.
                </p>
              </div>
              <div>
                <img src={model3} />
              </div>

              <div className="column1text">
                <h3>Quality Education</h3>
                <p>
                  Initiatives in education and healthcare have far-reaching
                  effects, from increased literacy rates and improved
                  educational outcomes to better health indicators and
                  strengthened healthcare systems.
                </p>
              </div>
              <div>
                <img src={model5} />
              </div>
            </div>
            <div className="modelColumn2">
              <img src={modeldivider} />
              {/* <img src={modeldivider1} /> */}
            </div>
            <div className="modelColumn3">
              <div className="column3text">
                <h3>Creating Livelihoods</h3>
                <p>
                  By directly addressing the root causes of poverty and
                  unemployment, our initiatives lead to the creation of
                  sustainable, dignified livelihood opportunities, significantly
                  impacting individual lives and community well-being.
                </p>
              </div>
              <div>
                <img src={model2} />
              </div>
              <div className="column3text">
                <h3>Healthcare</h3>
                <p>
                  By directly addressing the root causes of poverty and
                  unemployment, our initiatives lead to the creation of
                  sustainable, dignified livelihood opportunities, significantly
                  impacting individual lives and community well-being.
                </p>
              </div>
              <div>
                <img src={model4} />
              </div>
              <div className="column3text">
                <h3>Promoting Equality</h3>
                <p>
                  Our commitment to equal opportunities manifests in targeted
                  programs for marginalized and underrepresented groups,
                  ensuring inclusivity and diversity in all our initiatives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
