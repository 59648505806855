import React from 'react'
import insta from "../assests/insta.svg";
import facebook from "../assests/facebook.svg";
import twitter from "../assests/twitter.svg";
import linkedin from "../assests/linkedin.svg";
import "../styles/aboutpage.scss"
import teamImg from "../assests/team-img.png"
import wasu from "../assests/wasu-img.png"
import twitter2 from "../assests/twitter2.svg"
import wasuCircle from "../assests/wasu-circle.png"
import sunita from "../assests/sunita-img.png"
import pathwayImg1 from "../assests/Variant1.png"
import pathwayImg2 from "../assests/Variant2.png";
import pathwayImg3 from "../assests/Variant3.png";
import teamMobImg1 from "../assests/silaigram-teambgmobImg1.png"
import teamMobImg2 from "../assests/silaigram-teambgmobImg2.png";
import teamImg2 from "../assests/aboutTeamImg2.png"
const About = () => {
  return (
    <div className="aboutpage-container">
      <div className="about-bg">
        <div className="about-content">
          <div>
            {/* <img src={silaigramlogo} /> */}
            <h1>
              <span style={{ fontFamily: "'Inria Serif', serif" }}>1</span>{" "}
              Million more livelihoods
            </h1>
          </div>
          <p>Want to become a volunteer?</p>
          <div className="btnposition">
            <a className="">Join Us</a>
          </div>
        </div>
        <div className="socialmedia">
          <hr />
          <div className="socialmediaicon">
            <img src={twitter} alt='' className="twitter" />
            <img src={facebook} alt='' className="facebook" />
            <img src={insta} alt='' className="insta" />
            <img src={linkedin} alt='' className="linkedin" />
          </div>
          <hr className="secondhr" />
        </div>
      </div>

      <div className="stories-section">
        <div className="storiess-content">
          <div className="history-textt"></div>
          <div className="history-img">
            <h1>Stories</h1>
          </div>
        </div>
        <div className="stories-content">
          <div className="history-text">
            <h1>History of Silaigram</h1>
            <p>
              Silaigram embarked on its journey in 2018, initially established
              as a platform dedicated to selling handmade cloth bags created by
              underprivileged women in rural areas. The enterprise was driven by
              a dual mission: providing employment to women in remote regions
              and offering an eco-friendly alternative to plastic by using
              cotton fabric. The goal was not just commercial but also
              environmental – to move towards a greener, cleaner community with
              sustainable solutions for daily use.
            </p>
            <p>
              As time progressed, Silaigram evolved beyond its original scope.
              Today, it stands as a robust sustainable community with an
              ambitious objective of creating 1 million livelihoods. The
              organization's scope has broadened significantly, addressing
              critical issues such as poverty alleviation, educational equality,
              comprehensive healthcare, and equal opportunity for everyone. Our
              initiatives and projects, multi-dimensional in nature and designed
              for the highest impact, reflect our commitment to driving systemic
              change. Rooted in its founding values and responding to evolving
              challenges, Silaigram continues to grow and make meaningful
              contributions to society
            </p>
          </div>
          <div className="history-img">
            <img src={teamImg} alt='' className="teamImg1" />
            <img src={teamImg2} alt='' className="teamImg2" />
          </div>
        </div>
      </div>
      <div className="founder-section">
        <div className="founder-content">
          <div className="founder-img">
            <img src={wasu} alt='' />
          </div>
          <div className="founder-message">
            <h1>A message from our founder</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur. Tristique accumsan odio
              fringilla elementum. Nulla egestas eget orci rhoncus feugiat at ut
              arcu. Tempus magnis tortor vitae scelerisque nec accumsan cursus
              aenean porta. Lectus sit pellentesque turpis diam cras sed.
            </p>
            <div className="social-mediass">
              <img src={twitter2}  alt="" />
              <p>Lorem ipsum dolor sit amet consectetur.</p>
            </div>
            <div className="social-mediass">
              <img src={facebook}  alt="" />
              <p>Lorem ipsum dolor sit amet consectetur.</p>
            </div>
            <div className="social-mediass">
              <img src={insta} alt='' className="instagram" />
              <p>Lorem ipsum dolor sit amet consectetur.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="team-main-container">
        <h1>Team of Silaigram</h1>

        <div className="team-section">
          <div className="team-content">
            <div className="team-imgs">
              <div className="wasu-img">
                <img src={wasuCircle} alt="" />
                <h4>Wasudev Mishra</h4>
                <p>Founder</p>
              </div>
              <div className="sunita-img">
                <img src={sunita} alt="" />
                <h4>Sunita Mishra </h4>
                <p>Co Founder</p>
              </div>
              <div className="yamini-img">
                <img src={sunita} alt="" />
                <h4>Yamini Mishra </h4>
                <p>Co Founder</p>
              </div>
              <div className="nilam-img">
                <img src={sunita} alt="" />
                <h4>Nilam Thakur </h4>
                <p>Co Founder</p>
              </div>
              <div className="charvi-img">
                <img src={sunita} alt='' />
                <h4> Charvi Chandekar</h4>
                <p>Team Member</p>
              </div>
            </div>
          </div>
        </div>
        <div className="team-section-mobile">
          <img src={teamMobImg1} alt=""  />
          <img src={teamMobImg2} alt="" />
        </div>
      </div>
      <div className="pathway-section">
        <h1>Our Pathway to success!</h1>
        <div className="pathway-images">
          <div>
            <img src={pathwayImg3} alt="" />
          </div>
          <div>
            <img src={pathwayImg2} alt="" />
          </div>
          <div>
            <img src={pathwayImg1} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About